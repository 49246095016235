<template>
  <div class="main">
    <el-row>
      <el-col :span="24">
        <el-row :gutter="40">
          <el-col :span="16">
            <div class="content kefan">
              <!-- 确认所在园区  -->
              <div class="range" id="glhtrk">
                <h4 class="title">管理后台入口</h4>
                <el-divider></el-divider>
                <p class="enterance">
                  <ul>
                    <li>1.智慧湾：https://admin.kefan.com/</li>
                    <li>2.智慧坊：https://zhf.wis-p.cn/</li>
                    <li>3.交运智慧湾：https://jyzhw.jy-wisdombay.cn/</li>
                    <li>4.智慧七立方：https://wis777.kefan.com/</li>
                    <li>5.科房旗下园区：https://kf.kefan.com/</li>
                  </ul>
                </p>
              </div>
              <!-- 合同查找 -->
              <div class="range" id="htcz">
                <h4 class="title">合同查找</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击左侧栏目“合同管理”(如图1.7)进入到合同管理页面（如图1.8）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.aurl[0]"
                :preview-src-list="img.aurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.7</p>
                <el-image 
                style="width:100%;"
                :src="img.burl[0]"
                :preview-src-list="img.burl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.8</p>
                <p class="describe">
                  2.在合同管理标题下方方框区域（如图1.9）可对合同进行类别筛选
                </p>
                <el-image 
                style="width:100%;"
                :src="img.curl[0]"
                :preview-src-list="img.curl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.9</p>
                <p class="describe">
                    3.点击最左侧“客户名称”灰色方框（如图1.10所示）可弹出下拉方框选择筛选“客户名称”、“合同编号”，在右侧“请输入内容”白色方框中输入对应的客户全称或关键字、合同编号全称或关键字，点击右侧放大镜按钮即可通过“客户名称”、“合同编号”完成合同查找
                </p>
                <el-image 
                style="width:100%;"
                :src="img.durl[0]"
                :preview-src-list="img.durl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.10</p>
                <p class="describe">
                    4.点击红色方框内“选择房间”（如图1.11所示）跳转出“选择关联房间”方框（如图1.12所示）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.eurl[0]"
                :preview-src-list="img.eurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.11</p>
                <el-image 
                style="width:100%;"
                :src="img.furl[0]"
                :preview-src-list="img.furl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.12</p>
                 <p class="describe">
                    5.可在红色方框内（如图1.13所示）对楼栋楼层进行选择
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.gurl[0]"
                :preview-src-list="img.gurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.13</p>
                 <p class="describe">
                    6.  “所在楼栋”下拉方框可选择楼栋，“所在楼层”下拉方框可选择楼层，选择完毕即可在下方跳出该楼栋楼层所包含房间（白色房间为可出租空置中房间，灰色房间为已出租中房间），点击目标房间即可完成选择（如图1.14所示）点击右下方“确定”按钮后即可通过筛选相应房间完成合同查找
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.hurl[0]"
                :preview-src-list="img.hurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.14</p>
                 <p class="describe">
                    7.  下拉方框右侧有“全选X号楼”、点击该按钮即可全选x号楼所有房间（如图1.15所示），点击右下方“确定”按钮后即可通过筛选相应楼栋完成合同查找
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.iurl[0]"
                :preview-src-list="img.iurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.15</p>
                 <p class="describe">
                    8. 在选完X楼栋后，点击“全选X层”按钮，即可全选X号楼X层所有房间（如图1.16所示），点击右下方“确定”按钮后即可通过筛选相应楼栋楼层完成合同查找
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.jurl[0]"
                :preview-src-list="img.jurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.16</p>
              </div>

              <!-- 日期查询 -->
              <div class="range" id="rqcx">
                <h4 class="title">日期查询</h4>
                <el-divider></el-divider>
                 <p class="describe">
                    1.点击“到期日范围”方框（如图1.17所示）即可跳出日期选择方框
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.kurl[0]"
                :preview-src-list="img.kurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.17</p>

                <p class="describe">
                    2.日期方框中点击具体的开始到结束日期即可通过筛选“到期日范围”完成合同查找（如图1.18所示）
                 </p>
                <el-image 
                style="width:100%;"
                :src="img.lurl[0]"
                :preview-src-list="img.lurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.18</p>
                
              </div>
              <!-- 筛选查询 -->
              <div class="range" id="sxcx">
                <h4 class="title">筛选查询</h4>
                <el-divider></el-divider>
                    <p class="describe">
                     1. “到期日期范围”方框右侧有“经办人”选择框（如图1.19所示），点击后弹出下拉框可选择目标合同经办人（经办合同人员通常为招商人员）即可通过经办人筛选完成合同查找
                    </p>
                    <el-image 
                style="width:100%"
                :src="img.murl[0]"
                :preview-src-list="img.murl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.19</p>
                <p class="describe">
                  2. “经办人”方框右侧有“合同状态”选择框（如图1.20所示），点击后弹出下拉框可选择目标合同状态（正常、预签、作废、结束）即可通过合同状态筛选完成合同查找
                </p>
                <el-image 
                style="width:100%"
                :src="img.nurl[0]"
                :preview-src-list="img.nurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.20</p>
                <p class="describe">
                  3. “合同状态”方框右侧有“合同类型”选择框（如图1.21所示），点击后弹出下拉框可选择合同类型（普通租赁、协议租赁、场地租赁、外摆协议、虚拟注册、公寓合同）即可通过合同类型筛选完成合同查找
                </p>
                <el-image 
                style="width:100%;"
                :src="img.ourl[0]"
                :preview-src-list="img.ourl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.21</p>
                <!-- 4 -->
                <p class="describe">
                  4.“查询”按钮右侧有“导出”按钮（如图1.22所示），在完成合同筛选后点击“导出”按钮即可导出当前筛选合同表格
                </p>
                <el-image 
                style="width:100%;"
                :src="img.purl[0]"
                :preview-src-list="img.purl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.22</p>
              </div>
              <!-- 合同账单查看和筛选 -->
              <div class="range" id="htzdckhsx">
                <h4 class="title">合同账单查看和筛选</h4>
                <el-divider></el-divider>
                <p class="describe">
                  1.点击小程序网页左侧“合同管理”跳转至合同管理页面，在客户名称右侧的白框中输入客户公司名称并点击最右侧蓝色按钮“查询”后即可显示该客户所有合同信息（如图1.25）
                </p>
                <el-image 
                style="width:100%"
                :src="img.surl[0]"
                :preview-src-list="img.surl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.25</p>
                <p class="describe">
                  2.找到需要修改账单的合同，点击最右侧方框“更多操作”跳出下拉方框选择“合同账单”（如图1.26）
                </p>
                <el-image 
                style="width:100%;"
                :src="img.turl[0]"
                :preview-src-list="img.turl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.26</p>
                <!-- 3 -->
                <p class="describe">
                  3.即可跳出目标合同的账单（如图1.27）红色方框选择的内容可以进行账单类型、账单状态、账单金额、账单周期的筛选
                </p>
                <el-image 
                style="width:100%;"
                :src="img.uurl[0]"
                :preview-src-list="img.uurl"
                fit="'contain'"
                ></el-image>
                <p style="text-align:center">图1.27</p>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="col kefan">
              <el-timeline>
                  <ul>
                    
                    <li v-for="(activity, index) in lists" :key="index" >
                      <el-timeline-item
                      :icon="activity.icon"
                      :type="activity.type"
                      :color="activity.color"
                      :size="activity.size"
                      :timestamp="activity.timestamp">
                      <a :href="activity.target" @click="tolink(index)" :class="link==index?'islink':''">{{activity.name}}</a>
                    </el-timeline-item>
                    </li>
                    <li><el-progress :text-inside="true" :stroke-width="20" :percentage="percentage" color="#4fc08d"></el-progress></li>
                  </ul>
                  
                </el-timeline>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      percentage: 0,
      lists: [
        {
          name: "管理后台入口",
          target: "#glhtrk",
        },
        {
          name: "合同查找",
          target: "#htcz",
        },
        {
          name: "日期查询",
          target: "#rqcx",
        },
        {
          name: "筛选查询",
          target: "#sxcx",
        },
        {
            name:"合同账单查看和筛选",
            target:"#htzdckhsx"
        }
      ],
      link: 0,
      img: {
        aurl: [this.$img + "cwhtgl/1.7.png"],
        burl: [this.$img + "cwhtgl/1.8.png"],
        curl: [this.$img + "cwhtgl/1.9.png"],
        durl: [this.$img + "cwhtgl/1.10.png"],
        eurl: [this.$img + "cwhtgl/1.11.png"],
        furl: [this.$img + "cwhtgl/1.12.png"],
        gurl: [this.$img + "cwhtgl/1.13.png"],
        hurl: [this.$img + "cwhtgl/1.14.png"],
        iurl: [this.$img + "cwhtgl/1.15.png"],
        jurl: [this.$img + "cwhtgl/1.16.png"],
        kurl: [this.$img + "cwhtgl/1.17.png"],
        lurl: [this.$img + "cwhtgl/1.18.png"],
        murl: [this.$img + "cwhtgl/1.19.png"],
        nurl: [this.$img + "cwhtgl/1.20.png"],
        ourl: [this.$img + "cwhtgl/1.21.png"],
        purl: [this.$img + "cwhtgl/1.22.png"],
        qurl: [this.$img + "cwhtgl/1.23.png"],
        rurl: [this.$img + "cwhtgl/1.24.png"],
        surl: [this.$img + "cwhtgl/1.25.png"],
        turl: [this.$img + "cwhtgl/1.26.png"],
        uurl: [this.$img + "cwhtgl/1.27.png"],
        vurl: [this.$img + "cwhtgl/1.28.png"],
        wurl: [this.$img + "cwhtgl/1.29.png"],
        xurl: [this.$img + "cwhtgl/1.30.png"],
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.getscroll);
  },
  methods: {
    tolink(index) {
      this.link = index;
    },
    // 获取滚动百分比
    getscroll() {
      // 页面总高
      var totalH = document.documentElement.scrollHeight;
      // 可视高
      var clientH = document.documentElement.clientHeight;
      // 计算有效高
      var validH = totalH - clientH;

      // 滚动条卷去高度
      var scrollH = document.documentElement.scrollTop;

      // 百分比
      var result = Math.round(((scrollH / validH) * 10000) / 100, 2);
      this.percentage = result;
      // console.log("totalH:" + totalH);
      // console.log("clientH:" + clientH);
      // console.log("scrollH:" + scrollH);
      if (result < 4) {
        this.tolink(0);
      } else if (result >= 4 && result < 66) {
        this.tolink(1);
      } else if (result >= 66 && result < 77) {
        this.tolink(2);
      } else if (result >= 77 && result < 94) {
        this.tolink(3);
      }  else {
        this.tolink(4);
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.getscroll);
  },
};
</script>

<style scoped>
.describe {
  font-size: 14px;
  color: #5e6d82;
}
.el-divider--horizontal {
  width: 80px;
  margin: 10px 0 15px 0;
  height: 3px;
  background-color: #4fc08d;
  box-shadow: 0px 3px 7px #4fc08d;
}
.range {
  margin: 0 0 10% 0;
}
.content p {
  width: 95%;
  word-break: break-all;
  word-wrap: break-word;
  padding: 10px 0;
}
.content {
  padding: 20px 40px;
}
:target {
  padding-top: 80px;
  margin-top: -80px;
}
.col {
  position: fixed;
  top: 80px;
  width: 300px;
  /* height: 30%; */
}
.col a {
  color: #5e6d82;
}
.col a:hover {
  color: #4fc08d;
}
.col a:after {
  color: #4fc08d;
  font-weight: 700;
}
.col .islink {
  color: #4fc08d;
  font-weight: 700;
}
.enterance {
  background: #5e6d82;
  color: aliceblue;
  border-radius: 8px;
  font-size: 14px;
  /* color: #5e6d82; */
}
.enterance li {
  padding: 5px 10px;
}
.describe::first-letter {
  font-size: 20px;
  font-weight: 700;
  text-shadow: 3px 2px 2px #4fc08d;
}
</style>